import { TOPIC_EXAMPLES } from "./constants";
// Show topic examples using

const TopicExamples = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="absolute left-4 h-6 cursor-text overflow-hidden"
    >
      <div className="flex animate-textRoller flex-col">
        {TOPIC_EXAMPLES.map((topic) => (
          <h2
            key={topic}
            className="relative w-full overflow-hidden whitespace-nowrap text-base font-semibold text-gray-400"
          >
            {topic}
          </h2>
        ))}
      </div>
    </div>
  );
};

export default TopicExamples;
