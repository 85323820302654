import {
  MagnifyingGlassIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import { useState, Fragment, FormEvent, KeyboardEvent, useRef } from "react";
import { Transition, Listbox, Combobox } from "@headlessui/react";
import { useRouter } from "next/router";
import {
  collection,
  getDocs,
  where,
  query,
  orderBy,
  startAt,
  endAt,
  limit,
  documentId,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";

import TopicExamples from "./TopicExamples";
import { TOPICS } from "constants/topics";
import { db } from "utils/firebase";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface TopicSearchProps {
  defaultSearchQuery?: string;
}

const TopicSearch = ({ defaultSearchQuery = "" }: TopicSearchProps) => {
  const searchRef = useRef(null);
  const router = useRouter();

  const [searchFocused, setSearchFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);
  const [selectedTopic, setSelectedTopic] = useState(null);

  // All  special characters including spaces replaced by +
  const cleanSearch = () => {
    return searchQuery
      ? searchQuery.replace(/[^a-zA-Z0-9]/g, "+").toLowerCase()
      : "0";
  };

  const [topicsData, loadingTopicData, errorTopicData] = useCollection(
    query(
      collection(db, "topics"),
      orderBy(documentId(), "asc"),
      startAt(cleanSearch()),
      endAt(cleanSearch() + "\uf8ff"),
      limit(5)
    )
  );

  const handleSearchQueryChange = (e: FormEvent<HTMLInputElement>) =>
    setSearchQuery(e.currentTarget.value);

  const navigateToSearch = (query: string) => {
    router.push(`/search/${encodeURI(query.split(" ").join("+"))}`);
  };

  const handleSearchKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && searchQuery) {
      navigateToSearch(searchQuery);
    }
  };

  const handleSelectTopic = (topic) => {
    setSelectedTopic(topic);
    setSearchQuery(topic.name);
    navigateToSearch(topic.name);
  };

  const handleSearchPress = () => {
    if (searchQuery) {
      navigateToSearch(searchQuery);
    }
  };

  const handleSearchInputBlur = () => {
    if (!searchQuery) {
      setSearchFocused(false);
    }
  };

  const handleTopicExamplesPress = () => {
    setSearchFocused(true);
    searchRef.current.focus();
  };

  const filteredTopics =
    searchQuery === ""
      ? searchQuery
      : TOPICS.filter((topic) => {
          return topic.name.toLowerCase().includes(searchQuery.toLowerCase());
        });

  const readableSearchQuery =
    router?.query?.q?.toString().split("+").join(" ") ?? "";

  return (
    <div className="flex w-screen max-w-3xl flex-col items-center justify-center px-1 md:px-8 lg:px-0">
      <div className="flex  w-[80%] flex-col items-center justify-center">
        <h3 className="sr-only">Topic</h3>

        <div className="relative flex w-full min-w-0 flex-row items-center justify-center py-2  ">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <Combobox
              as="div"
              value={searchQuery || selectedTopic}
              defaultValue={searchQuery || selectedTopic}
              onChange={handleSelectTopic}
              onKeyUp={handleSearchKeyPress}
            >
              {({ open }) => (
                <>
                  <Combobox.Label className="sr-only block text-sm font-medium leading-6 text-gray-900">
                    Search by topic
                  </Combobox.Label>
                  <div className="relative">
                    <Combobox.Input
                      ref={searchRef}
                      className={`block w-full rounded-3xl border border-gray-300 bg-white py-2 pl-4 pr-8 text-sm placeholder-gray-500 focus:border-emerald-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-emerald-500 sm:text-sm`}
                      onChange={(event) => setSearchQuery(event.target.value)}
                      onFocus={() => setSearchFocused(true)}
                      onBlur={handleSearchInputBlur}
                      defaultValue={searchQuery}
                      placeholder={`${searchFocused ? "Enter a topic" : ""}`}
                    />
                    <Combobox.Button
                      onClick={handleSearchPress}
                      className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                    >
                      <MagnifyingGlassIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>

                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {topicsData?.docs?.map((topic) => {
                        const topicData = topic?.data();

                        return (
                          <Combobox.Option
                            key={topic.id}
                            value={topicData}
                            className={({ active }) =>
                              classNames(
                                "relative cursor-pointer select-none py-2.5 pl-3 pr-9",
                                active ? "bg-gray-100" : "text-gray-900"
                              )
                            }
                          >
                            {({ active, selected }) => (
                              <>
                                <span
                                  className={classNames(
                                    "block truncate",
                                    selected && "font-semibold"
                                  )}
                                >
                                  {topicData.name}
                                </span>
                              </>
                            )}
                          </Combobox.Option>
                        );
                      })}

                      {searchQuery.length > 0 && (
                        <Combobox.Option
                          value={{ id: null, name: searchQuery }}
                          className={({ active }) =>
                            classNames(
                              "relative flex cursor-pointer select-none items-center py-0.5 px-3 ",
                              active ? "bg-gray-100" : "text-gray-900"
                            )
                          }
                        >
                          {({ active, selected }) => (
                            <>
                              <span
                                className={classNames(
                                  "inline-block truncate font-normal",
                                  selected && "font-semibold"
                                )}
                              >
                                {searchQuery}
                              </span>

                              <span className="background-shine via-95% from-90% ml-4 inline-block w-min whitespace-nowrap rounded-md bg-gradient-to-r from-emerald-600 via-emerald-500 to-emerald-600 px-2 py-1 text-sm text-white">
                                AI Search
                              </span>
                            </>
                          )}
                        </Combobox.Option>
                      )}
                    </Combobox.Options>
                  </div>
                </>
              )}
            </Combobox>
          </div>
          {!searchFocused && !searchQuery && (
            <TopicExamples onClick={handleTopicExamplesPress} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopicSearch;

{
  /* <input
autoComplete="off"
id="search"
name="search"
className="block w-full rounded-3xl border border-gray-300 bg-white py-2 text-sm placeholder-gray-500 focus:border-emerald-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-emerald-500 sm:text-sm"
type="search"
value={searchQuery}
placeholder="Enter a topic"
onChange={handleSearchQueryChange}
onKeyUp={handleSearchKeyPress}
/> */
}
