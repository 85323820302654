const LANDING_NAVIGATION_OPTIONS = [
  { name: "About", href: "/about" },
  { name: "Actions", href: "/search/Climate+change" },
  // { name: "able", href: "/actionable-content" },
  // { name: "Content", href: "/content" },
];

const LANDING_BOTTOM_NAVIGATION_OPTIONS = [
  { name: "Terms", href: "/terms-and-conditions" },
  { name: "Privacy", href: "/privacy" },
  { name: "Contact", href: "/contact" },
];

const LANDING_USER_NAVIGATION_OPTIONS = [
  { name: "Home", href: "/" },
  { name: "Profile", href: "/profile" },
  { name: "Settings", href: "/settings" },
];

const TEAM = [
  {
    name: "Ray Chooi",
    role: "CTO",
    imageUrl: "/landing/ray_chooi.png",
    portfolioUrl: "https://raychooi.com/",
    twitterUrl: "https://twitter.com/Raymando282",
    linkedinUrl: "https://www.linkedin.com/in/raymond-chooi/",
  },
];

const TOP_SEARCH = {
  topic: "climate+change",
  readableTopic: "Climate Change",
  title: "350.org",
  description:
    "A global movement to build a fossil free future. They work to raise awareness about climate change and to build support for action to address it",
  homeLink: "https://350.org/",
  donateLink: "https://act.350.org/donate/build/",
};

const TOPIC_EXAMPLES = [
  "Climate change",
  "Gender equality",
  "Poverty",
  "Animal abuse",
  "Water contamination",
  "Conflict",
  "Drug abuse",
  "Child abuse",
  "Global warming",
  "Racism",
];

export {
  LANDING_NAVIGATION_OPTIONS,
  LANDING_BOTTOM_NAVIGATION_OPTIONS,
  LANDING_USER_NAVIGATION_OPTIONS,
  TEAM,
  TOP_SEARCH,
  TOPIC_EXAMPLES,
};
